body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background: #111;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #252830;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Grid {
  background: #111;
  width: 100%;
  min-height: 200px;
  padding: 40px 0;
}

.grideo {
  display: inline-block;
  vertical-align: top;
  width: 270px;
  height: 180px;
  margin: 10px;
  padding: 10px 20px;
  border: 2px solid #eee;
  position: relative;
}

.grideo-title {
  color: #fff;
  padding: 2px 5px;
  width: calc(100% - 20px);
  font: normal normal 500 15px/15px Arial, sans-serif;
  text-align:left;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  background: rgba(0,0,0,0.5);
}

.grideo-duration {
  color: #fff;
  padding: 2px 5px;
  font: normal normal 700 14px/14px Arial, sans-serif;
  text-align:left;
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 10;
  background: rgba(0,0,0,0.5);
}

h1 .grey {
  color: #888;
  font-size: 60%;
  font-weight: 400;
}
