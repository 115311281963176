body {
  background: #111;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #252830;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Grid {
  background: #111;
  width: 100%;
  min-height: 200px;
  padding: 40px 0;
}

.grideo {
  display: inline-block;
  vertical-align: top;
  width: 270px;
  height: 180px;
  margin: 10px;
  padding: 10px 20px;
  border: 2px solid #eee;
  position: relative;
}

.grideo-title {
  color: #fff;
  padding: 2px 5px;
  width: calc(100% - 20px);
  font: normal normal 500 15px/15px Arial, sans-serif;
  text-align:left;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
  background: rgba(0,0,0,0.5);
}

.grideo-duration {
  color: #fff;
  padding: 2px 5px;
  font: normal normal 700 14px/14px Arial, sans-serif;
  text-align:left;
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 10;
  background: rgba(0,0,0,0.5);
}

h1 .grey {
  color: #888;
  font-size: 60%;
  font-weight: 400;
}